/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-background-color: #f2f4f3;
}

:root,
:root[mode='md'],
:root[mode='ios'] {
  --ion-font-family: 'Mulish';
  font-family: var(--ion-font-family) !important;
  --box-shadow-primary: 0 0 8px 4px rgba(0, 0, 0, 0.06);
  --abs-blue: #1d3d60;
  --abs-red: #d8042a;
  --abs-mid-blue: #1b2c75;
  --abs-light-blue: #457b9d;
  --abs-bg-gray: #f2f4f3;
  --abs-text-muted: #aca8a8;
  --abs-card-radius: 18px;
  --abs-auth-background: linear-gradient(to bottom, #ebedec, #e3e4e6);
}

body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

ion-header {
  box-shadow: none;
}

ion-app {
  --background: transparent;
  background-color: white;
}

.logo_header {
  height: 1.3em;
}

.h3header {
  margin-bottom: 0;
  font-style: italic;
  font-weight: 400;
}

.pheader {
  margin-top: 0;
  font-weight: 300;
}

.bgcat {
  background: rgba(13, 44, 58, 0.75);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.h3cat {
  margin-bottom: 0;
  font-style: italic;
  color: #ffffff;
  line-height: 1;
  font-weight: 600;
  position: relative;
  z-index: 99;
  font-size: 1.3em;
}

.h3catslide {
  margin-bottom: 0;
  font-style: italic;
  color: #ffffff;
  line-height: 1;
  font-weight: 600;
  position: relative;
  z-index: 99;
  font-size: 1em;
  text-align: left;
  margin-top: 0;
  padding-left: 10px;
}

.h3list {
  font-style: italic;
  font-weight: 500 !important;
  font-size: 1em !important;
}

.pcat {
  margin-top: 0;
  color: #ffffff;
  position: relative;
  z-index: 99;
}

.btnsearch {
  --box-shadow: 0 4px 16px rgb(202 62 44 / 51%);
}

.olprice {
  color: #c5c2c2;
  text-decoration: line-through;
}

.item-list {
  border-bottom: 1px solid #f1f1f1;
  --padding-start: 5px;
}

.itemlistresumen {
  border-bottom: 1px solid #f1f1f1;
}

.item-list ion-thumbnail {
  margin-inline-end: 10px;
}

.inputqti {
  --padding-end: 0;
  margin-inline-start: 0;
  text-align: center;
  margin-inline-end: 0;
}

.redshadow {
  box-shadow: 0 3px 10px 0 #ca3d2c;
  --box-shadow: 0 0 black;
  border-radius: 10px;
  --border-radius: 10px;
}

.label-stacked {
  font-weight: 500;
}

.titleheader {
  margin-left: 10px;
  font-size: 2em;
  color: #0b1447;
  font-weight: 700;
  border-bottom: 1px solid #f1f1f1;
  margin-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.subtitleheader {
  margin-left: 10px;
  font-size: 1.3em;
  color: #0b1447;
  font-weight: 700;
  border-bottom: 1px solid #f1f1f1;
  margin-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.cardbrand {
  text-align: center !important;
}

.sizevertodo {
  font-size: 0.85em;
}

.iconnot {
  font-size: 5em;
  margin-top: 30vh;
}

.bottom-button {
  --background: #0f2c78;
  --border-radius: 6rem;
  --box-shadow: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.bottom-button::part(native) {
  padding: 1.2rem;
}

.bottom-button[disabled] {
  opacity: 1;
  color: #ababab;
  --background: #51689e;
}

.auth-card {
  box-shadow:
    rgba(0, 0, 0, 0.2) 0 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;

  position: relative;
  border-radius: 3rem;
  width: 100%;
  padding: 2.5rem;
  overflow: unset;
  margin: 0;
  color: var(--abs-blue);
}

.cart-button {
  display: flex;
  width: min-content;
  color: #26366d;
  min-height: min-content;
  overflow: visible;
  --background: #fac035;

  --padding-top: 2px;
  --padding-bottom: 2px;
  --padding-start: 0.6em;
  --padding-end: 0.6em;

  span {
    align-self: start;
  }

  ion-icon {
    font-size: 25px;
  }
}

@font-face {
  font-family: 'SonusMedium', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Sonus-Medium.otf');
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  src: url('assets/fonts/Mulish-VariableFont_wght.ttf');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Mulish';
  font-weight: normal;
  font-style: italic;
  src: url('assets/fonts/Mulish-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Kredit Front';
  font-weight: normal;
  font-style: normal;
  src: url('assets/fonts/Kredit Front.otf');
}

ion-toolbar.sc-ion-searchbar-ios-h,
ion-toolbar .sc-ion-searchbar-ios-h {
  padding: 0;
}

.header-page {
  background: #ffffff;
  min-height: 10vh;
  overflow: hidden;
  border-radius: 0 0 3em 3em;
  padding-bottom: 2em;

  ion-toolbar {
    --background: transparent;
  }
}

.ion-list-content {
  display: flex;
  flex-direction: column;

  row-gap: 0.7rem;
  /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
}

.scrollbar-hidden::-webkit-scrollbar {
  background-color: transparent;
}

.products-slide {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  column-gap: 0.5rem;
  padding-bottom: 0;
}

.main-action-btn {
  all: unset;

  & > .clear {
    background-color: white;
  }

  .success {
    background-color: var(--ion-color-success);
  }
}

.abs-card {
  border-radius: 18px;
  padding: 18px;
  box-shadow: var(--box-shadow-primary);
}

/* FORM CONTROL */

.form-control {
  @apply mb-4 flex flex-col;
}

.form-control > label {
  @apply mb-2 text-sm text-slate-600;
}

.form-control > select {
  background-color: #f2f2f2;
  @apply h-9 rounded-lg px-1;
}

.form-control > input[type='text'] {
  @apply border-b p-1 outline-none;
}
