:root {
  /** ABS Blue **/
  --ion-color-abs-blue: #0f2c78;
  --ion-color-abs-blue-rgb: 15, 44, 120;
  --ion-color-abs-blue-contrast: #ffffff;
  --ion-color-abs-blue-contrast-rgb: 255, 255, 255;
  --ion-color-abs-blue-shade: #0d276a;
  --ion-color-abs-blue-tint: #274186;

  /** ABS Red **/
  --ion-color-abs-red: #e53945;
  --ion-color-abs-red-rgb: 229, 57, 69;
  --ion-color-abs-red-contrast: #000000;
  --ion-color-abs-red-contrast-rgb: 0, 0, 0;
  --ion-color-abs-red-shade: #ca323d;
  --ion-color-abs-red-tint: #e84d58;

  /** Abs Orange **/
  --ion-color-abs-orange: #ffa62b;
  --ion-color-abs-orange-rgb: 255, 166, 43;
  --ion-color-abs-orange-contrast: #000000;
  --ion-color-abs-orange-contrast-rgb: 0, 0, 0;
  --ion-color-abs-orange-shade: #e09226;
  --ion-color-abs-orange-tint: #ffaf40;
}

.ion-color-abs-blue {
  --ion-color-base: var(--ion-color-abs-blue);
  --ion-color-base-rgb: var(--ion-color-abs-blue-rgb);
  --ion-color-contrast: var(--ion-color-abs-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-abs-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-abs-blue-shade);
  --ion-color-tint: var(--ion-color-abs-blue-tint);
}

.ion-color-abs-red {
  --ion-color-base: var(--ion-color-abs-red);
  --ion-color-base-rgb: var(--ion-color-abs-red-rgb);
  --ion-color-contrast: var(--ion-color-abs-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-abs-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-abs-red-shade);
  --ion-color-tint: var(--ion-color-abs-red-tint);
}

.ion-color-abs-orange {
  --ion-color-base: var(--ion-color-abs-red);
  --ion-color-base-rgb: var(--ion-color-abs-red-rgb);
  --ion-color-contrast: var(--ion-color-abs-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-abs-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-abs-red-shade);
  --ion-color-tint: var(--ion-color-abs-red-tint);
}

.ion-color-abs-orange {
  --ion-color-base: var(--ion-color-abs-orange);
  --ion-color-base-rgb: var(--ion-color-abs-orange-rgb);
  --ion-color-contrast: var(--ion-color-abs-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-abs-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-abs-orange-shade);
  --ion-color-tint: var(--ion-color-abs-orange-tint);
}
